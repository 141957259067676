import { RefObject } from 'react'
import SingleTile from './SingleTile'
import { IEntity } from '@mpe/api-client/models/entities/entity'

import usePagination from '@/hooks/usePaginaton'

import tiles from './tiles.module.css'
import { StaticEntity } from '@/consts/types'
import { getNextPatternIndex } from './utils'
import Pagination from '../Pagination/Pagination'

interface TilesProps {
  initialEntities: (IEntity | StaticEntity)[]
  pagesCount: number
  topRef: RefObject<any>
  getEntities?: (callback: Function, currentPage: number) => Promise<void>
  page?: number
  onPageChange?: (page: number) => void
  forcedLayout?: number[]
}

export default function Tiles({
  initialEntities,
  pagesCount,
  page,
  onPageChange,
  topRef,
  getEntities,
  forcedLayout
}: TilesProps) {
  const { currentEntities, currentPage, onPrev, onNext, onPage } = usePagination(
    initialEntities,
    getEntities,
    topRef,
    page,
    onPageChange
  )

  return (
    <>
      <div
        className={`tiles-grid grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 md:grid-rows-1 ${tiles.gridGap}`}
      >
        {currentEntities
          ? currentEntities.map((item, i) => {
              // only square and wide if less than six entities
              let patternIndex: any = getNextPatternIndex(currentEntities, i)
              if (forcedLayout) {
                patternIndex = forcedLayout[i] || 0
              }

              return (
                <SingleTile
                  key={`${item.name}-${i}`}
                  entity={item}
                  patternIndex={patternIndex || 0}
                  index={i}
                />
              )
            })
          : null}
      </div>
      {pagesCount > 1 && (
        <Pagination
          pagesCount={pagesCount}
          currentPage={currentPage}
          onPrev={onPrev}
          onNext={onNext}
          onPage={onPage}
        />
      )}
    </>
  )
}
