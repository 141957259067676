import { tilePattern } from '@/components/Tiles/consts'
import { Entity } from '@mpe/api-client/models/entities/entity'
import { getTileImageProps } from './image'

type TileImageSourceData = {
  sourceDescription?: string
  originHref?: string
}

interface GetTileImageSourceDataProps {
  entity: Entity<any>
  patternIndex: number
}

function isNotNull(val: any) {
  return val !== null && val !== 'null'
}

function getTileImageSourceData({
  entity,
  patternIndex
}: GetTileImageSourceDataProps): TileImageSourceData {
  const result: TileImageSourceData = {}

  try {
    const imageProps = getTileImageProps(tilePattern[patternIndex] as any, entity)

    const { idealImageUrl, mtvaImageUrl, mtvaImageHash, fortepanImageSource, customImageSource } =
      imageProps

    const isFortepanImage = idealImageUrl && fortepanImageSource && isNotNull(fortepanImageSource)
    const isMTVAImage = !idealImageUrl && mtvaImageUrl && mtvaImageHash
    const isCustomImage = customImageSource && isNotNull(customImageSource)

    if (isFortepanImage) {
      result.sourceDescription = `Forrás: ${fortepanImageSource}`
    } else if (isMTVAImage) {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      ;(result.sourceDescription = `Forrás: MTVA, Nemzeti Fotótár`),
        (result.originHref = `https://archivum.mtva.hu/photobank/item/${mtvaImageHash}`)
    } else if (isCustomImage) {
      result.sourceDescription = `Forrás: ${customImageSource}`
    }
  } catch (e) {
    console.log(e)
  }

  return result
}

export { getTileImageSourceData }
