import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Entity } from '@mpe/api-client/models/entities/entity'
import { Audio } from '@mpe/api-client/models/entities/audio'
import { AudioTrack } from '@mpe/api-client/models/audio-track'

import { hide } from '@/store/slices/modalSlice'
import { audioPlaylistsSelector, PlaylistsState } from '@/store/slices/audioPlaylistsSlice'
import { getAudioPlaylists, updateAudioPlaylists } from '@/store/thunks/audioPlaylistsThunk'
import { DEFAULT_PLAYLIST } from '@/consts'
import { Playlist } from '@/components/PlaylistModal'

import { PlaylistModal } from '../PlaylistModal'
import { PlaylistType } from '../NewCustomPlaylistModal/NewCustomPlaylistModal'
import { showToaster } from '@/store/slices/toasterSlice'

interface AudioPlaylistModalProps {
  entity: Entity<Audio>
}

const AudioPlaylistModal = ({ entity }: AudioPlaylistModalProps) => {
  const dispatch = useDispatch()
  const initialPlaylists: PlaylistsState = useSelector(audioPlaylistsSelector)
  const initialOwnedPlaylists: Playlist[] = initialPlaylists?.data
    .filter((playlist) => playlist && playlist.name !== DEFAULT_PLAYLIST)
    .map((playlist) => {
      return {
        id: playlist['@id'],
        name: playlist.name,
        selected: !!playlist?.tracks
          ?.map((t: AudioTrack) => t.audio['@id'])
          .includes(entity['@id']),
        public: playlist.public
      }
    })

  const onSubmit = async (playlists: Playlist[]) => {
    if (!entity) {
      return
    }

    const selectedPlaylistIds = playlists
      .filter((item: Playlist) => item.selected)
      .map((item: Playlist) => item.id)

    const response = dispatch(updateAudioPlaylists(String(entity.id), selectedPlaylistIds))

    if (!response) {
      dispatch(showToaster('fail', 'A hozzáadás nem sikerült'))
      return
    }

    dispatch(hide())
  }

  useEffect(() => {
    if (!initialOwnedPlaylists.length) {
      dispatch(getAudioPlaylists())
    }
  }, [])

  return (
    <PlaylistModal
      entity={entity}
      title="Zeneszám lejátszási listához adása"
      onSubmit={onSubmit}
      initialPlaylists={initialOwnedPlaylists}
      playlistType={PlaylistType.Audio}
    />
  )
}

export default AudioPlaylistModal
