import { useState, useEffect } from 'react'

export default function usePagination(
  entities: any[],
  getEntities?: (callback: Function, currentPage: number) => Promise<void>,
  scrollToRef?: { current: HTMLElement },
  page?: number,
  onPageChanged?: (page: number) => void
) {
  const [currentEntities, setCurrentEntities] = useState<any[]>(entities)
  const [currentPage, setCurrentPage] = useState(page || 1)
  const scrollToPageTop = () => {
    if (
      scrollToRef &&
      scrollToRef.current &&
      typeof scrollToRef.current.scrollIntoView === 'function'
    ) {
      scrollToRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }
  const onPrev = () => {
    scrollToPageTop()
    let newPage = currentPage + -1
    if (newPage < 1) newPage = 1
    setCurrentPage(newPage)
    onPageChanged && onPageChanged(newPage)
  }
  const onNext = () => {
    scrollToPageTop()
    const newPage = currentPage + 1
    setCurrentPage(newPage)
    onPageChanged && onPageChanged(newPage)
  }
  const onPage = (newPage: number) => {
    scrollToPageTop()
    setCurrentPage(newPage)
    onPageChanged && onPageChanged(newPage)
  }

  useEffect(() => {
    if (getEntities) {
      getEntities(setCurrentEntities, currentPage)
    }
  }, [currentPage, getEntities])

  useEffect(() => {
    setCurrentEntities(entities)
  }, [entities])

  useEffect(() => {
    if (page) {
      setCurrentPage(page)
    }
  }, [page])

  return {
    currentEntities,
    currentPage,
    onPrev,
    onNext,
    onPage
  }
}
