import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Entity } from '@mpe/api-client/models/entities/entity'
import { Video } from '@mpe/api-client/models/entities/video'
import { VideoPlaylist } from '@mpe/api-client/models/video-playlist'
import { VideoTrack } from '@mpe/api-client/models/video-track'

import { hide } from '@/store/slices/modalSlice'
import { videoPlaylistsSelector } from '@/store/slices/videoPlaylistsSlice'
import { getVideoPlaylists, updateVideoPlaylists } from '@/store/thunks/videoPlaylistsThunk'
import { DEFAULT_VIDEO_PLAYLIST } from '@/consts'
import { PlaylistType } from '@/components/NewCustomPlaylistModal/NewCustomPlaylistModal'

import { Playlist, PlaylistModal } from '../PlaylistModal'

interface PlaylistSelectorModalProps {
  entity: Entity<Video>
}

const VideoPlaylistModal = ({ entity }: PlaylistSelectorModalProps) => {
  const dispatch = useDispatch()
  const initialPlaylists = useSelector(videoPlaylistsSelector)

  const initialOwnedPlaylists = initialPlaylists.data
    .filter((playlist) => playlist && playlist.name !== DEFAULT_VIDEO_PLAYLIST)
    .map((playlist: VideoPlaylist) => {
      return {
        id: playlist['@id'],
        name: playlist.name,
        selected: !!playlist.tracks
          ?.map((item: VideoTrack) => item.video['@id'])
          .includes(entity['@id']),
        public: playlist.public
      }
    })

  const onSubmit = async (playlists: Playlist[]) => {
    if (!entity) {
      return
    }

    const selectedPlaylistIds = playlists
      .filter((item: Playlist) => item.selected)
      .map((item: Playlist) => item.id)

    dispatch(updateVideoPlaylists(String(entity.id), selectedPlaylistIds))
    dispatch(hide())
  }

  useEffect(() => {
    if (!initialPlaylists.data.length) {
      dispatch(getVideoPlaylists())
    }
  }, [])

  return (
    <PlaylistModal
      entity={entity}
      title="Videó lejátszási listához adása"
      onSubmit={onSubmit}
      playlistType={PlaylistType.Video}
      initialPlaylists={initialOwnedPlaylists}
    />
  )
}

export default VideoPlaylistModal
