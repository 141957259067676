import { MouseEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as Sentry from '@sentry/nextjs'
import { Entity } from '@mpe/api-client/models/entities/entity'
import { authSelector } from '@/store/slices/authSlice'
import { show } from '@/store/slices/modalSlice'
import {
  favoritesSelector,
  isEntityFavoritedSelector,
  toggleFavorite
} from '@/store/slices/favoritesSlice'
import { formatDateString, getEntityType, getEntityUrl, getTypeIcon, getTypeString } from '@/utils'
import { getAlbumNameFromImageName, getTileImageProps } from '@/utils/image'
import { getTileImageSourceData } from '@/utils/entity-helpers'

import AudioPlaylistModal from '../AudioPlaylistModal/AudioPlaylistModal'
import VideoPlaylistModal from '../VideoPlaylistModal'
import { Link } from '../Link/Link'
import Icon from '../Icon'
import { gradients, tilePattern } from './consts'
import SingleTileImage from './SingleTileImage'

import tileImageClasses from './singleTileImage.module.css'
import tiles from './tiles.module.css'

const containerClasses: { [key: string]: string } = {
  wide: `${tiles.imageContainerWide} md:col-span-2`,
  square: tiles.imageContainerSquare,
  portrait: `${tiles.imageContainerPortrait} md:row-span-2`
}

export interface ExtendedEntity extends Entity<any> {
  [key: string]: any
}

interface SingleTileProps {
  entity: ExtendedEntity
  patternIndex: number
  index: number
}

export default function SingleTile({ entity, patternIndex }: SingleTileProps) {
  const dispatch = useDispatch()
  const auth = useSelector(authSelector)
  const isFavoritesLoading = useSelector(favoritesSelector).isLoading
  const isFavorited = useSelector(isEntityFavoritedSelector(entity['@id']))

  const imageProps = getTileImageProps(tilePattern[patternIndex] as any, entity)
  const imageSourceData = getTileImageSourceData({ entity, patternIndex })

  const entityType = getEntityType(entity)
  const bgGradientClasses = gradients[entityType] || gradients.default
  const classes = {
    ...tileImageClasses,
    container: `${tiles.singleTile} ${containerClasses[imageProps.tileType]} cursor-pointer z-0`,
    bg: `absolute w-full h-full bg-gradient-to-tr ${bgGradientClasses}`,
    imageOverlay:
      'absolute w-full h-full bottom-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-60 z-20',
    textContainer:
      'w-full h-full absolute bottom-0 p-6 text-white z-30 flex flex-col overflow-hidden',
    textEyebrowContainer: 'flex flex-row items-center mt-auto',
    textEyebrowContainerStatic: 'flex flex-row items-center mt-auto justify-end',
    textEyebrowIcon: 'w-4 mr-3 text-white',
    textEyebrow: 'text-sm mr-3',
    textEyebrowArrow: 'w-10 text-white',
    textTitle:
      'mt-3 text-3xl font-bold tracking-wide overflow-hidden text-ellipsis md:mt-4 md:text-2xl lg:text-base xl:text-2xl',
    textTitleStatic: 'text-white',
    copyrightInfo: 'absolute top-0 left-0 p-6 z-30',
    textDescription: 'text-md text-white',
    bgStatic: `text-white absolute w-full h-full bg-gradient-to-br ${gradients.static}`
  }
  const iconMap: { [key: string]: string } = {
    Audio: 'add-to-playlist',
    Video: 'add-to-video-playlist'
  }
  const iconName = getTypeIcon(String(entityType))
  const isImage = 'Image' === entityType
  let eyebrowText = getTypeString(String(entityType))

  if (entity['localizedRole']) {
    eyebrowText = entity['localizedRole'].charAt(0).toUpperCase() + entity['localizedRole'].slice(1)
  }

  if (isImage) {
    if (entity && entity.name && entity.name.includes('(')) {
      const albumName = getAlbumNameFromImageName(entity.name)
      eyebrowText = `Kép ebből a galériából: ${albumName}`
    }
  }

  const onFavoriteClick = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()

    if (isFavoritesLoading) {
      return
    }

    switch (entityType) {
      case 'Audio':
        dispatch(show({ modalComponent: <AudioPlaylistModal entity={entity} /> }))
        break
      case 'Video':
        dispatch(show({ modalComponent: <VideoPlaylistModal entity={entity} /> }))
        break
      default:
        dispatch(toggleFavorite(entity['@id']))
        break
    }
  }

  // format entity dates
  const { dateFrom, dateTo } = entity
  let formattedDateFrom
  let formattedDateTo

  const yearIsEnough = [
    'Band',
    'MusicalPeriod',
    'Publication',
    'PublicationItem',
    'Venue',
    'Audio'
  ].includes(String(entityType))

  if (dateFrom) {
    formattedDateFrom = dateFrom.length > 4 ? formatDateString(dateFrom, yearIsEnough) : dateFrom
  }

  if (dateTo) {
    formattedDateTo = dateTo.length > 4 ? formatDateString(dateTo, yearIsEnough) : dateTo
  }

  const descriptionMaxLength = 100

  let entityEndpoint = null
  try {
    entityEndpoint = entity.isStatic ? entity.href : getEntityUrl(entity)
  } catch (error) {
    Sentry.captureException(error, {
      extra: {
        component: 'SingleTile',
        entity_id: entity.id
      }
    })

    return null
  }

  return entity.isStatic ? (
    <Link href={entityEndpoint} className={`${classes.container} z-1`} title={entity.name}>
      <div className={classes.bgStatic}>
        <div className={classes.textContainer}>
          <div>
            <h3
              className={`${
                entity.titleClass
                  ? entity.titleClass
                  : classes.textTitle + ' ' + classes.textTitleStatic + ' ' + tiles.title
              }`}
            >
              {entity.name}
            </h3>
            <h4
              className={`${
                entity.descriptionClass
                  ? entity.descriptionClass
                  : classes.textDescription + ' ' + tiles.description
              }`}
              title={entity.description}
            >
              {entity.description.length > (entity.maxDescription || descriptionMaxLength)
                ? `${entity.description.substring(
                    0,
                    entity.maxDescription || descriptionMaxLength
                  )}...`
                : entity.description}
            </h4>
          </div>
          <div className={classes.textEyebrowContainerStatic}>
            <>
              <span className={classes.textEyebrow}>tovább</span>
            </>
            <Icon name="arrow-right" className={classes.textEyebrowArrow} />
          </div>
        </div>
      </div>
    </Link>
  ) : (
    <Link href={entityEndpoint} className={`${classes.container} z-1`} title={entity.name}>
      <div className={classes.bg}>
        <SingleTileImage {...imageProps} classes={classes} />

        <div className={classes.imageOverlay} />
        <div className={classes.textContainer}>
          <div className={classes.textEyebrowContainer}>
            <>
              <Icon name={iconName} className={classes.textEyebrowIcon} />
              <span className={classes.textEyebrow}>{eyebrowText}</span>
            </>
            <Icon name="arrow-right" className={classes.textEyebrowArrow} />
          </div>
          {!isImage && <h3 className={`${classes.textTitle} ${tiles.title}`}>{entity.name}</h3>}
          {entity.dateFrom ? (
            <div className={classes.textEyebrow}>
              {`${formattedDateFrom}${formattedDateTo ? ` - ${formattedDateTo}` : ''}`}
            </div>
          ) : (
            <></>
          )}
        </div>

        <Link
          href={imageSourceData.originHref || '#'}
          target={imageSourceData.originHref ? '_blank' : ''}
          className={classes.copyrightInfo}
          title={imageSourceData.sourceDescription || ''}
          onClick={(e) => e.stopPropagation()}
        >
          {imageSourceData.sourceDescription && <Icon name="info" className="w-6 " />}
        </Link>

        {auth.isAuth && (
          <div
            className="absolute top-0 right-0 p-6 z-40"
            style={{ opacity: isFavoritesLoading ? '.5' : '1' }}
          >
            <Icon
              name={iconMap[entityType] ?? 'star'}
              className="w-10 cursor-pointer"
              fill={isFavorited}
              onClick={onFavoriteClick}
            />
          </div>
        )}
      </div>
    </Link>
  )
}
