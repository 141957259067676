import { hide, show } from '@/store/slices/modalSlice'
import { useDispatch } from 'react-redux'
import Button from '../Button'
import Icon from '../Icon'

import playlistModalStyles from './playlistModalStyles.module.css'
import { ExtendedEntity } from '../Tiles/SingleTile'
import { useEffect, useState } from 'react'
import NewCustomPlaylistModal, {
  PlaylistType
} from '../NewCustomPlaylistModal/NewCustomPlaylistModal'
import { Spinner } from '../Spinner'

export interface Playlist {
  id: string
  name: string
  selected: boolean
  public?: boolean
}

interface PlaylistModalProps {
  title: string
  initialPlaylists: Playlist[]
  onSubmit: (playlists: Playlist[]) => void
  entity: ExtendedEntity
  playlistType: PlaylistType
}

export function PlaylistModal({
  title,
  initialPlaylists,
  onSubmit,
  entity,
  playlistType
}: PlaylistModalProps) {
  const dispatch = useDispatch()
  const [ownedPlaylists, setOwnedPlaylists] = useState<Playlist[] | undefined>()

  const onItemClick = (selected: Playlist) => {
    setOwnedPlaylists(
      (playlists) =>
        playlists?.map((playlist) =>
          playlist.id !== selected.id ? playlist : { ...playlist, selected: !playlist.selected }
        )
    )
  }

  const newPlaylist = () =>
    dispatch(
      show({
        modalComponent: <NewCustomPlaylistModal type={playlistType} entity={entity} />
      })
    )

  useEffect(() => {
    setOwnedPlaylists(initialPlaylists)
  }, [initialPlaylists.length])

  return (
    <div className="flex flex-col w-full overflow-hidden shadow-lg" style={{ maxHeight: '80vh' }}>
      <div className="flex w-full p-5 h-20 items-center bg-gradient-to-tr from-tomato to-indigo">
        <h4 className="text-xl font-bold">{title}</h4>
        <Icon
          name="plus"
          className="rotate-45 ml-auto cursor-pointer"
          onClick={() => dispatch(hide())}
        />
      </div>
      <div className="flex flex-col p-5 bg-black overflow-y-auto ">
        {!ownedPlaylists && <Spinner className={'flex flex-row justify-center'} />}
        {!ownedPlaylists?.length && (
          <span className="bold text-center">Még nincs lejátszási listád.</span>
        )}
        {ownedPlaylists &&
          ownedPlaylists.map((playlist: Playlist, index: number) => (
            <div
              key={index}
              className="flex items-center pb-2 mb-2 cursor-pointer border-white border-b-2"
              onClick={() => onItemClick(playlist)}
            >
              <label
                htmlFor={`list-${index}-yes`}
                className="pointer-events-none select-none text-xl"
              >
                {playlist.public && (
                  <Icon
                    name="globe"
                    className="h-5 align-middle white mr-2 opacity-50 inline-block"
                  />
                )}
                {playlist.name}
              </label>
              <input
                type="checkbox"
                id={`list-${index}-yes`}
                name={`list-${index}-confirmation`}
                value="yes"
                className={`${playlistModalStyles.customCheckbox} opacity-0 absolute h-0 w-0`}
                checked={playlist.selected}
                readOnly
              />
              <div className="ml-auto bg-white border-2 w-6 h-6 flex shrink-0 justify-center items-center">
                <Icon
                  name="check-mark"
                  className={`hidden fill-current w-3 h-3 pointer-events-none ${playlistModalStyles.customCheckboxIcon}`}
                />
              </div>
            </div>
          ))}
      </div>
      <div className="flex pb-5 bg-black">
        <Button className="mt-5 mr-2 mx-auto w-40 shadow-none underline" onClick={newPlaylist}>
          Új lejátszási lista
        </Button>
        <Button
          className="mt-5 mx-auto w-40 bg-gradient-to-b from-tomato to-indigo hover:from-indigo hover:to-tomato"
          onClick={() => onSubmit(ownedPlaylists || [])}
        >
          Rendben
        </Button>
      </div>
    </div>
  )
}
