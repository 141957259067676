import { useRouter } from 'next/router'
import Icon from '../Icon'
import { Link } from '../Link/Link'

interface PaginationProps {
  currentPage: number
  pagesCount: number
  onPrev: () => void
  onNext: () => void
  onPage: (page: number) => void
}

export default function Pagination({
  pagesCount,
  currentPage,
  onPrev,
  onNext,
  onPage
}: PaginationProps) {
  if (pagesCount <= 1) {
    return null
  }

  const classes = {
    list: 'pagination flex flex-wrap justify-center mt-16',
    arrow: (disabled?: boolean) =>
      `w-11 mr-10 cursor-pointer transition-all ${disabled ? 'opacity-50' : ' hover:text-skyBlue'}`,
    listItem: (current?: boolean) =>
      `page-number mr-10 cursor-pointer transition-all ${
        current ? ' text-skyBlue' : ' hover:text-skyBlue'
      }`
  }

  const router = useRouter()
  const category = router.query.category
  const prevPage = currentPage - 1
  const nextPage = currentPage + 1

  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === pagesCount
  const displayedPages: number[] = new Array(pagesCount)
    .fill(null)
    .map((n: null, i: number) => i)
    .slice(
      currentPage - 1 <= 2
        ? 0
        : currentPage <= pagesCount - 3
        ? currentPage - 3
        : pagesCount >= 5
        ? pagesCount - 5
        : 0,
      currentPage - 1 <= 2 ? 5 : currentPage + 2
    )
  const prependEllipsis = pagesCount > 6 && currentPage >= 4
  const appendEllipsis = pagesCount > 5 && currentPage <= pagesCount - 3

  const firstLink = isFirstPage ? '/' + category + '/1' : '/' + category + '/' + prevPage
  const lastLink = isLastPage ? '/' + category + '/' + pagesCount : '/' + category + '/' + nextPage

  // use linking only if category is present in router
  const useHref = !!category

  return (
    <ul className={classes.list}>
      <Link href={firstLink} useHref={useHref}>
        <Icon
          disableHover
          name="arrow-left"
          className={classes.arrow(isFirstPage)}
          onClick={isFirstPage ? () => {} : onPrev}
        />
      </Link>
      {prependEllipsis && (
        <Link
          href={'/' + category + '/1'}
          className={classes.listItem(false)}
          data-testid="leading-ellipsis"
          key="leading-ellipsis"
          onClick={() => onPage(1)}
          useHref={useHref}
        >
          <span className="mr-1">1</span> ...
        </Link>
      )}
      {displayedPages.map((i) => {
        const page = i + 1
        return (
          <Link
            href={'/' + category + '/' + page}
            className={classes.listItem(currentPage === page)}
            key={page}
            onClick={() => onPage(page)}
            useHref={useHref}
          >
            {page}
          </Link>
        )
      })}
      {appendEllipsis && (
        <Link
          href={'/' + category + '/' + pagesCount}
          className={classes.listItem(false)}
          key="trailing-ellipsis"
          data-testid="trailing-ellipsis"
          onClick={() => onPage(pagesCount)}
          useHref={useHref}
        >
          ... <span className="ml-1">{pagesCount}</span>
        </Link>
      )}
      <Link href={lastLink} useHref={useHref}>
        <Icon
          disableHover
          name="arrow-right"
          className={classes.arrow(isLastPage)}
          onClick={isLastPage ? () => {} : onNext}
        />
      </Link>
    </ul>
  )
}
