import { lastTilePattern, tilePattern } from './consts'

// returns the calculated pattern index for tile @ i index
export const getNextPatternIndex = (entities: any, i: number) => {
  if (entities.length <= 5) {
    return i % 3 ? 2 : 0
  }
  // we can modify this amount of tiles' pattern index at the back of penult row
  const lookupTilesCount = 4

  const tilesCountInNewRow = entities.length % 8
  // originally calculated pattern index
  let patternIndex = i % tilePattern.length
  if (!tilesCountInNewRow) {
    return patternIndex
  }

  // from this index we should recalculate the pattern index
  const recalcIndexStart = entities.length - tilesCountInNewRow - lookupTilesCount
  if (i >= recalcIndexStart) {
    // get new indices from 0 to 7
    const newPatternIndex = i - recalcIndexStart
    // the new pattern
    const newPattern = lastTilePattern[tilesCountInNewRow][newPatternIndex] || null
    if (newPattern !== null) {
      // get the index for new pattern
      patternIndex = tilePattern.findIndex((pat) => pat === newPattern)
    }
  }
  return patternIndex
}
